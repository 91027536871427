import Button from 'Components/Button'
import Heading from 'Components/Heading'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { MANAGE_BOOKING } from 'Routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import Spinner from 'Components/Spinner'
import moment from 'moment'
import CSVModal from 'Components/Modals/CSVModal'
import EmptyState from 'Components/EmptyState'
import { ShareIcon, ClockIcon } from '@heroicons/react/outline'
import store from 'store'
import ManualBookingView from 'Components/Bookings/ManualBooking/ManualBookingView'
import AppointoPagination from 'Components/AppointoPagination'
import { ItemsPerPage } from 'Redux/genericReducers'

const headers = [
  'Service Name',
  'Appointment Time',
  'Duration',
  'Team Member',
  'Customer Info',
  '',
]

const Booking = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [bookings, setBookings] = useState([])
  const bookingsFetching = useSelector((state) => state.booking.listFetching)
  const listDataOffset = useSelector((state) => state.booking.listDataOffset)
  const bookingsList = useSelector((state) => state.booking.list)
  const [showCsvModal, setShowCsvModal] = useState(false)
  const [showManualModal, setShowManualModal] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [selected, setSelected] = useState(0)
  const [bookingStatus, setBookingStatus] = useState('upcoming')
  const currentTab = useSelector((state) => state.navigation.currentTab)
  const [isLoading, setIsLoading] = useState(false)

  const userData = store.get('userData')

  useEffect(() => {
    dispatch(BookingActions.bookingsListRequest({ status: 'upcoming' }))
  }, [])

  const bookNow = (booking) => () => {
    window.open(
      `${booking.booking_link}&fromAdmin=true&bookingConflict=true`,
      '_blank'
    )
  }

  const removeConflict = (c) => () => {
    setIsLoading(true)
    dispatch(
      BookingActions.deleteConflict(c.id, () => {
        setIsLoading(false)
        dispatch(BookingActions.conflictsRequest())
      })
    )
  }

  const shareLink = (b) => () => {
    let text = `${window.location.origin}${MANAGE_BOOKING}/${
      b.id
    }?cancelled=${!!b.cancelled}`
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Copied')
        alert('Copied the text: ' + text)
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }

  useEffect(() => {
    if (bookingsList?.length > 0) {
      let s = bookingsList.map((b, i) => {
        return [
          b.product_name,
          moment(b.selected_time).format('llll'),
          {
            value: (
              <div className="flex gap-2 items-center ">
                <ClockIcon className="w-5" />
                <p>{b.duration} mins</p>
              </div>
            ),
            key: `duration${i}`,
          },
          b.team_member?.name,
          {
            value:
              !!b.customers &&
              Object.entries(
                b.customers.reduce(
                  (acc, { name }) => ((acc[name] = (acc[name] || 0) + 1), acc),
                  {}
                )
              ).map(([name, count]) => (
                <p key={name}>{`${name}${count > 1 ? ` x ${count}` : ''}`}</p>
              )),
            key: `customers${i}`,
          },
          {
            value: (
              <div className="flex gap-2.5">
                {/* <Button
                    type="defaultGray"
                    title={<ShareIcon className="w-5" color="#5B93FF" />}
                    onClick={shareLink(b)}
                  /> */}
                <Button
                  type="default"
                  title="Manage"
                  onClick={() => {
                    history.push(
                      `${MANAGE_BOOKING}/${b.id}?cancelled=${!!b.cancelled}`
                    )
                  }}
                />
              </div>
            ),
            type: 'Button',
          },
        ]
      })
      setBookings(s)
    }
  }, [bookingsList, currentTab])

  useEffect(() => {
    if (currentTab === 'cancelled') {
      dispatch(BookingActions.bookingsCancelListRequest({}))
    } else if (currentTab === 'upcoming') {
      dispatch(BookingActions.bookingsListRequest({ status: currentTab }))
    } else if (currentTab === 'past') {
      dispatch(BookingActions.bookingsListRequest({ status: currentTab }))
    }
    setBookingStatus(currentTab)
  }, [currentTab])

  const exportCSV = () => {
    setShowCsvModal(true)
  }

  const manualBooking = () => {
    setShowManualModal(true)
  }

  const onPrevious = () => {
    dispatch(
      BookingActions.bookingsListRequest(
        { status: bookingStatus },
        listDataOffset - ItemsPerPage
      )
    )
  }

  const onNext = () => {
    dispatch(
      BookingActions.bookingsListRequest(
        { status: bookingStatus },
        listDataOffset + ItemsPerPage
      )
    )
  }

  return (
    <div>
      {showManualModal ? (
        <ManualBookingView
          showModal={showManualModal}
          handleClose={(success) => {
            setShowManualModal(false)
            if (success) {
              setShowSuccessToast(true)
              setSelected(0)
              dispatch(
                BookingActions.bookingsListRequest({ status: 'upcoming' })
              )
            }
          }}
          setShowManualModal={setShowManualModal}
          user={props.user}
          shop={props.shop}
        />
      ) : (
        <>
          <Heading
            title="Bookings"
            rightSection={
              <div className="flex gap-3 items-center">
                <Button
                  title="Export Bookings CSV"
                  onClick={exportCSV}
                  type="default"
                />
                <Button
                  type="primary"
                  title="+Manual Booking"
                  onClick={manualBooking}
                />
              </div>
            }
          />

          <div className="my-6">
            {bookingsFetching ? (
              <Spinner />
            ) : bookingsList.length === 0 ? (
              <EmptyState
                header="NO Bookings"
                subHeader="Please book a time to show bookings here"
              />
            ) : (
              <>
                <Table headers={headers} data={bookings} />
                <AppointoPagination
                  offset={listDataOffset}
                  onPrevious={onPrevious}
                  list={bookings}
                  onNext={onNext}
                />
              </>
            )}
          </div>

          {showCsvModal && (
            <CSVModal
              showModal={showCsvModal}
              setShowModal={setShowCsvModal}
              shop={props.shop}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Booking
