import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import Spinner from 'Components/Spinner'
import styles from 'Styles/RescheduleBooking.module.css'
import BookingActions from 'Redux/BookingRedux'
import Modal from 'Components/Modal'
import Checkbox from 'Components/Checkbox'
import store from 'store'
import { TrackEvent } from 'Themes/constants'
import DatePicker from './DatePicker'

const RescheduleBookingDetails = (props) => {
  const dispatch = useDispatch()
  const [availFetching, setAvailFetching] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState({})
  const [slots, setSlots] = useState([])
  const [calendlyDays, setCalendlyDays] = useState([])
  const [timezone, setTimeZone] = useState()

  const [selectedDate, setSelectedDate] = useState()
  const [sendCustomerUpdate, setSendCustomerUpdate] = useState(true)

  const handleSendCustomerUpdate = useCallback((newChecked) => {
    setSendCustomerUpdate(newChecked)
  }, [])

  const setAvailData = async (
    appointmentId,
    selectedDate,
    forceFetch = false
  ) => {
    try {
      let day = moment(selectedDate).format('YYYY-MM-DD')
      const shouldFetch = appointmentId
        ? !!calendlyDays && !!calendlyDays.calendly_events
          ? calendlyDays.calendly_events.days.find((d) => d.date === day)
          : false
        : false

      if (!shouldFetch || forceFetch) {
        let impersonated_tm = ''
        if (props.isWeb) {
          impersonated_tm = `&impersonated_tm=${props.user.id}`
        } else {
          if (props.teamMemberId) {
            impersonated_tm = `&impersonated_tm=${props.teamMemberId}`
          }
        }

        let soM = moment(selectedDate).startOf('month').format('YYYY-MM-DD')
        let eoM = moment(selectedDate).endOf('month').format('YYYY-MM-DD')

        let userData = store.get('userData')
        setAvailFetching(true)
        let appointo_calendly_data
        if (userData.shop.flow === 'shopify') {
          appointo_calendly_data = await axios.get(
            `${
              process.env.REACT_APP_BASE_URL
            }/scripttag/calendar_availability?start_date=${soM}&end_date=${eoM}&appointment_id=${appointmentId}&timezone=${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }&${impersonated_tm}&booking_id=${props.bookingId}&shop_uuid=${
              userData.shop.shop_uuid
            }&shopify_domain=${userData.shop.shopify_domain}&duration_uuid=${
              props.durationUuid
            }`
          )
        } else {
          appointo_calendly_data = await axios.get(
            `${
              process.env.REACT_APP_BASE_URL
            }/dashboard/scripttag/calendar_availability?start_date=${soM}&end_date=${eoM}&appointment_id=${appointmentId}&timezone=${
              Intl.DateTimeFormat().resolvedOptions().timeZone
            }&${impersonated_tm}&booking_id=${props.bookingId}&shop_uuid=${
              userData.shop.shop_uuid
            }`
          )
        }
        setAvailFetching(false)

        if (
          !!appointo_calendly_data.data &&
          !!appointo_calendly_data.data.calendly_events
        ) {
          setCalendlyDays(appointo_calendly_data.data)

          let day_events =
            appointo_calendly_data.data.calendly_events.days.find(
              (d) => d.date === day
            ) || { spots: [] }
          setSlots(day_events.spots)
          setTimeZone(
            appointo_calendly_data.data.calendly_events.availability_timezone
          )
        }
      } else {
        let day_events = calendlyDays.calendly_events.days.find(
          (d) => d.date === day
        ) || { spots: [] }
        setSlots(day_events.spots)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const slotClicked = (slot) => () => {
    setSelectedSlot(slot)
  }

  const onChange = (selectedDates) => {
    setAvailData(props.appointmentId, selectedDates, true)
    setSelectedDate(selectedDates)
  }

  const onConfirmSlot = (slot) => () => {
    let params = {
      booking_id: props.bookingId,
      timestring: slot.start_time,
      send_customer_update: sendCustomerUpdate,
    }

    if (props.customerId) {
      params.customer_id = props.customerId
    }

    TrackEvent('Reschedule Booking', params)

    dispatch(
      BookingActions.bookingsRescheduleRequest(params, (resp) => {
        let booking = resp.response.booking
        dispatch(BookingActions.bookingsListRequest({ status: 'upcoming' }))
        dispatch(BookingActions.bookingsDetailRequest(booking.id))
        dispatch(BookingActions.bookingsKeyReset('detailData'))
        props.handleClose(booking.id)
      })
    )
  }

  return (
    <Modal
      showModal={props.showModal}
      onClose={() => props.handleClose(undefined)}
      title={props.title}
    >
      <div className="flex flex-col md:flex-row gap-6 p-4">
        <div className="w-full md:w-2/3">
          <div className="flex flex-col gap-3 mb-4">
            <Checkbox
              name="send-email"
              title="Send rescheduling email to customer"
              onClick={handleSendCustomerUpdate}
              value={sendCustomerUpdate}
            />
          </div>
          <div>
            <DatePicker
              onChange={onChange}
              selected={selectedDate}
              nextMonthButtonLabel=">"
              previousMonthButtonLabel="< "
              inline
              minDate={new Date()}
            />
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <p className="text-sm mb-2">
            {moment(selectedDate).format('dddd, MMM DD')} - Available Slots
          </p>
          {availFetching ? (
            <Spinner />
          ) : (
            <div
              style={
                slots.length > 0
                  ? { overflowY: 'scroll', maxHeight: '300px' }
                  : {}
              }
            >
              {slots.length ? (
                slots.map((slot, i) => {
                  return (
                    <div
                      key={`appointo-slot-${i}`}
                      className={`w-full my-2 ${
                        selectedSlot.start_time === slot.start_time
                          ? 'flex gap-2'
                          : ''
                      }`}
                    >
                      <div
                        className={`flex cursor-pointer gap-2 border-2  rounded-md py-2 px-1 ${
                          selectedSlot.start_time === slot.start_time
                            ? 'border-gray-500 bg-gray-500 text-white text-xs md:text-sm'
                            : 'border-green-800 text-green-800'
                        }`}
                        onClick={slotClicked(slot)}
                      >
                        {moment(slot.start_time).format('hh:mm A')}
                      </div>
                      {selectedSlot.start_time === slot.start_time && (
                        <div
                          className="border-2 border-green-800 text-green-800 rounded-md py-2 px-1 cursor-pointer text-xs md:text-sm"
                          onClick={onConfirmSlot(slot)}
                        >
                          Reschedule
                        </div>
                      )}
                    </div>
                  )
                })
              ) : (
                <p className="text-sm font-medium">
                  Uh-Oh. All time slots have been filled for the day. Please
                  choose another date
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default RescheduleBookingDetails
